import React, {useState, useEffect, useCallback} from 'react';
import '../css/layout.css';
import {Button, Row, Col} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const Signup = () => {
	const navigate = useNavigate();
	const [fullname, setFullname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const {login} = useAuth();

	const handleSubmit = useCallback(async(e) => {
		e.preventDefault();
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/signup',
									{
										method:'post',
										headers: {'Content-Type': 'application/json'}, 
										body:JSON.stringify({fullname,email,password}),
									});
		console.log(JSON.stringify(response));
		let data = await response.json();
		login({fullname:data?.fullname,email:data?.email,token:data?.token});
		navigate('/');
	},[navigate, login, fullname, email, password]);

	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>G</b>alichi</h1>
						</div>
                        <Button onClick={() => navigate('/login')} className="login-btn">Login</Button>
                    </nav>
					<Row className="h-100 d-flex align-items-center justify-content-center p-3">
					    <Col xs={12} md={4}>
					        <div class="card">
					            <div class="card-body d-flex justify-content-start">
					                <form class="w-100" action="/" method="POST">
					                	<div class="form-group w-100 mb-3">
					                        <input onChange={(e) => setFullname(e.target.value)} type="text" class="form-control w-100" id="fullname" name="fullname" placeholder="Fullname" required/>
					                    </div>
					                    <div class="form-group w-100 mb-3">
					                        <input onChange={(e) => setEmail(e.target.value)} type="text" class="form-control w-100" id="email" name="email" placeholder="Email" required/>
					                    </div>
					                    <div class="form-group w-100 mb-3">
					                        <input onChange={(e) => setPassword(e.target.value)} type="password" class="form-control w-100" id="password" name="password" placeholder="Password" required/>
					                    </div>
					                    <button onClick={handleSubmit} type="submit" class="btn btn-primary login-btn mb-3 w-100">Signup</button>
					                </form>
					            </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}