import React,{useRef, useState, useEffect, useCallback} from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom'
import '../css/welcome.css';
import { AutoplayVideo } from "@wethegit/react-autoplay-video";
import babyshow1 from '../assets/babyshower1.mp4';
import babyshow2 from '../assets/babyshower2.mp4';

export const Welcome = () => {
    const navigate = useNavigate();
    const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    const targetRef = useRef(null);
    const scrollToTarget = () => {
        if (targetRef.current) {
          targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [name, setName] = useState('');
    const [suggested_by, setSuggestedBy] = useState('');
    const [submit, setSubmitted] = useState(false);

    const handleSubmit = useCallback(async(e) => {
        e.preventDefault();
        let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/suggest',
                                    {
                                        method:'post',
                                        headers: {'Content-Type': 'application/json'}, 
                                        body:JSON.stringify({name,suggested_by}),
                                    });
        console.log(JSON.stringify(await response.json()));
        setSubmitted(true)
    },[setSubmitted, name, suggested_by])

    return  <div className="welcome-content">
                {/*<div className="welcome-video">
                    <video poster="https://images.ctfassets.net/xit7f234flxz/6MBHvLYHab6Rip2UvEbSgo/90b8be42b3db6acac5db7a5257834504/woman.jpg" width="100%" height="100%" playsinline>
                        <source type="video/webm" src="https://videos.ctfassets.net/xit7f234flxz/7BqsHwcJ0cjnSY77lhYyO7/5d5d9c6688a791d21cc651fa54f2896c/woman.webm"/>
                        <source type="video/mp4" src="https://videos.ctfassets.net/xit7f234flxz/1PO5rcY0JekmSu3nthvwOd/840fca76899602b16dd2732e68789773/woman.mp4"/>
                    </video>
                </div>*/}
                <div className="welcome-video">
                    <AutoplayVideo  src={babyshow1}
                                    posterImg=""
                                    description="This is a description of the video."
                                    prefersReducedMotion={prefersReducedMotion}
                                    style={{height:'100vh'}}
                                    className="autoPlayVideo"
                                    renderReducedMotionFallback={() => (
                                    <img src="" alt="Description of the fallback image." />
                                    )}/>
                </div>
                <div className="welcome-section">
                    <nav className="navbar">
                        <div className="welcome-logo" onClick={() => navigate('/')}>
                            <h1><b>B</b>aby <b>G</b>alichi</h1>
                        </div>
                        <Button onClick={() => navigate('/login')} className="login-btn">Login</Button>
                    </nav>
                    <Row className="header-content d-flex align-items-center justify-content-center">
                        <Col xs={12} md={8} className="inner-header-content">
                            <div className="welcomeText mb-3">Thanks for coming to Baby Galichi's baby shower</div>
                            <Button className="suggest-btn mb-3" onClick={scrollToTarget}>Suggest a baby name</Button>
                        </Col>
                    </Row>
                </div>
                <div className="welcome-section welcomeBgImg">
                    <Row className="h-100 d-flex align-items-center justify-content-center p-3">
                        <Col xs={12} md={4}>
                            <div class="card" ref={targetRef}>
                                <div class="card-body d-flex justify-content-start">
                                    <form class="w-100" action="/" method="POST">
                                        <label className="mb-3">Enter a baby name suggestion below. Thanks!</label>
                                        <div class="form-group w-100 mb-3">
                                            <input onChange={(e) => setName(e.target.value)} type="text" class="form-control w-100" id="fullname" name="fullname" placeholder="Baby Name" required/>
                                        </div>
                                        <div class="form-group w-100 mb-3">
                                            <input onChange={(e) => setSuggestedBy(e.target.value)} type="text" class="form-control w-100" id="email" name="email" placeholder="Suggested by" required/>
                                        </div>
                                        <button onClick={handleSubmit} type="submit" class="btn btn-primary login-btn mb-3 w-100">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
}