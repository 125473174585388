import { useContext, useCallback } from "react";
import { AuthContext } from "./useAuth";
import { useLocalStorage } from "./useLocalStorage";

export const useUser = () => {
  const { user, setUser } = useContext(AuthContext);
  const { setItem, getItem } = useLocalStorage();

  const addUser = useCallback((user) => {
    setUser(user);
    setItem("user", JSON.stringify(user));
  },[setUser, setItem]);

  const removeUser = useCallback(() => {
    setUser(null);
    setItem("user", "");
  },[setUser, setItem]);

  return { user:JSON.parse(getItem("user")), addUser, removeUser, setUser };
};