import { useState, useCallback } from "react";

export const useLocalStorage = () => {
  const [value, setValue] = useState(null);

  const setItem = useCallback((key, value) => {
    localStorage.setItem(key, value);
    setValue(value);
  },[setValue]);

  const getItem = useCallback((key) => {
    const value = localStorage.getItem(key);
    setValue(value);
    return value;
  },[setValue]);

  const removeItem = useCallback((key) => {
    localStorage.removeItem(key);
    setValue(null);
  },[setValue]);

  return { value, setItem, getItem, removeItem };
};