import React, {useState, useEffect, useCallback} from 'react';
import '../css/layout.css';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const SavedNames = () => {
	const navigate = useNavigate();
	const {user} = useAuth();
	const {token} = user;
	const [parent1name, setParent1name] = useState('');
	const [parent2name, setParent2name] = useState('');
	const [gender, setGender] = useState('');
	const [content, setContent] = useState([]);

	const loadContent = useCallback(async() => {
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/saved_names',
									{
										method:'get',
										headers: {'Content-Type': 'application/json','Authorization':`Bearer ${token}`}
									});
		console.log(JSON.stringify(await response.json()));
		let data = await response.json();
		setContent(data);
	},[token]);

	useEffect(() => {
		(async() => {await loadContent()})();
	},[loadContent]);

	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>G</b>alichi</h1>
						</div>
						<div className="loggedInBtns d-flex align-items-center">
							<div className="loggedInBtn" onClick={() => navigate('/home')}>Home</div>
							<div className="loggedInBtn" onClick={() => navigate('/suggestions')}>Suggestions</div>
							<div className="loggedInBtn" onClick={() => navigate('/saved')}>Saved</div>
						</div>
                        <Button onClick={() => navigate('/login')} className="logout-btn">Logout</Button>
                    </nav>
					<Row className="d-flex justify-content-center page-content">
					    <Col xs={12} md={6}>
					        <div class="card h-100 p-3">
						            <div class="d-flex align-items-center">
						                <div><b>Saved names</b></div>
						                <form class="save-form" action="/delete_all_saved_names" method="POST">
						                    <button type="submit" class="btn trash-btn">Delete all <i class="bi bi-trash"></i></button>
						                </form>
						            </div>
						            {content.map((value, index) => {
							            return 	<div className="list-container pt-2">
								                    <div class="list-item d-flex align-items-center">
								                        <div>{value.name}</div>
								                        <div class="save-form">
								                            <div>suggested by: {value.suggested_by}</div>
								                        </div>
								                        <form action="/delete_saved_name" method="POST">
								                            <input type="text" name="name" value={value.name} class="hide"/>
								                            <button type="submit" class="btn repeat-btn"><i class="bi bi-dash-circle"></i></button>
								                        </form>
								                    </div>
								                </div>
						            })}
					                <div class="h-100 w-100 d-flex align-items-center justify-content-center graybackground">
					                    <h5 class="graytext">No results</h5>
					                </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}