import { useEffect, createContext, useCallback, useMemo } from "react";
import { useUser, User } from "./useUser";
import { useLocalStorage } from "./useLocalStorage";

export const AuthContext = createContext({
  user: null,
  setUser: () => {},
});

export const useAuth = () => {
  // we can re export the user methods or object from this hook
  const { user, addUser, removeUser, setUser } = useUser();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem("user");
    if (user) {
      addUser(JSON.parse(user));
    }
  }, [addUser, getItem]);

  const login = useCallback((user) => {
    addUser(user);
  },[addUser]);

  const logout = useCallback(() => {
    removeUser();
  },[removeUser]);

  const authMethods = useMemo(() => ({
    user:JSON.parse(getItem("user")), login, logout, setUser
  }), [login, logout, setUser, getItem]);
  
  return authMethods;
};


