import React, {useState, useEffect, useCallback} from 'react';
import '../css/layout.css';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const Suggestions = () => {
	const navigate = useNavigate();
	const {user} = useAuth();
	const {token} = user;
	const [parent1name, setParent1name] = useState('');
	const [parent2name, setParent2name] = useState('');
	const [gender, setGender] = useState('');
	const [content, setContent] = useState([]);

	const loadContent = useCallback(async() => {
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/suggestions',
									{
										method:'get',
										headers: {'Content-Type': 'application/json','Authorization':`Bearer ${token}`}
									});
		console.log(JSON.stringify(await response.json()));
		let data = await response.json();
		setContent(data);
	},[token])

	useEffect(() => {
		(async() => {await loadContent()})();
	},[loadContent]);


	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>G</b>alichi</h1>
						</div>
						<div className="loggedInBtns d-flex align-items-center">
							<div className="loggedInBtn" onClick={() => navigate('/home')}>Home</div>
							<div className="loggedInBtn" onClick={() => navigate('/suggestions')}>Suggestions</div>
							<div className="loggedInBtn" onClick={() => navigate('/saved')}>Saved</div>
						</div>
                        <Button onClick={() => navigate('/login')} className="logout-btn">Logout</Button>
                    </nav>
					<Row className="h-100 d-flex justify-content-center page-content">
					    <Col xs={12} md={6}>
					        <div class="card">
					            <div class="card-body d-flex justify-content-start">
					            	<h4>results</h4>
					                {content.map((value, index) => {
					                	return <div>{value.name}</div>
					                })}
					            </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}