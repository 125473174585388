import React,{useState, useCallback} from 'react';
import '../css/layout.css';
import {Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const Home = () => {
	const navigate = useNavigate();
	const {user} = useAuth();
	const {token} = user;
	const [parent1name, setParent1name] = useState('');
	const [parent2name, setParent2name] = useState('');
	const [gender, setGender] = useState('');

	const handleSubmit = useCallback(async(e) => {
		e.preventDefault();
		let response = await fetch('https://babynames-worker-dev.yasminek.workers.dev/',
									{
										method:'post',
										headers: {'Content-Type': 'application/json','Authorization':`Bearer ${token}`}, 
										body:JSON.stringify({parent1name,parent2name,gender}),
									});
		console.log(JSON.stringify(await response.json()));
	},[parent1name, parent2name, gender, token]);

	return <div className="layout-content">
				<div className="layout-body bgImg">
					<nav className="navbar">
						<div className="website-logo" onClick={() => navigate('/')}>
							<h1><b>B</b>aby <b>G</b>alichi</h1>
						</div>
						<div className="loggedInBtns d-flex align-items-center">
							<div className="loggedInBtn" onClick={() => navigate('/home')}>Home</div>
							<div className="loggedInBtn" onClick={() => navigate('/suggestions')}>Suggestions</div>
							<div className="loggedInBtn" onClick={() => navigate('/saved')}>Saved</div>
						</div>
                        <Button onClick={() => navigate('/login')} className="logout-btn">Logout</Button>
                    </nav>
					<Row className="h-100 d-flex justify-content-center page-content">
					    <Col xs={12} md={6}>
					        <div class="card">
					            <div class="card-body d-flex justify-content-start">
					                <form class="w-100" action="/" method="POST">
					                    <label>Generate unique baby names by combining parent's names</label>
					                    <div class="form-group w-100">
					                        <input onChange={(e) => setParent1name(e.target.value)} type="text" class="form-control w-100" id="parent1Name" name="parent1Name" placeholder="Enter Parent 1 Name" required/>
					                    </div>
					                    <div class="form-group w-100">
					                        <input onChange={(e) => setParent2name(e.target.value)} type="text" class="form-control w-100" id="parent2Name" name="parent2Name" placeholder="Enter Parent 2 Name" required/>
					                    </div>
					                    <div class="form-group w-100">
					                        <label>Gender</label>
					                        <div class="form-check w-100">
					                            <input onChange={(e) => setGender(e.target.value)} class="form-check-input" type="radio" name="gender" id="male" value="male" required/>
					                            <label class="form-check-label" for="male">
					                                Male
					                            </label>
					                        </div>
					                        <div class="form-check w-100">
					                            <input onChange={(e) => setGender(e.target.value)} class="form-check-input" type="radio" name="gender" id="female" value="female" required/>
					                            <label class="form-check-label" for="female">
					                                Female
					                            </label>
					                        </div>
					                    </div>
					                    <button type="submit" class="btn btn-primary">Generate Baby Names</button>
					                </form>
					            </div>
					        </div>
					    </Col>
					    <Col xs={12} md={6}>
					        <div class="card">
					            <div class="card-body d-flex justify-content-start">
					            	<h4>results</h4>
					                
					            </div>
					        </div>
					    </Col>
					</Row>
				</div>
			</div>
}